var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert("#map {\r\n    width: 100%;\r\n    height: 600px;\r\n}\r\n.mapboxgl-popup {\r\n    max-width: 400px;\r\n}\r\n.mapboxgl-popup-content {\r\n    padding: 10px 10px 5px;\r\n}\r\n#btnPitch {\r\n    position: absolute;\r\n    bottom: 4px;\r\n    left: 4px;\r\n    border-radius: 4px;\r\n    /*width: 30px;*/\r\n    height: 30px;\r\n    min-width: 30px;\r\n    padding: 4px 7px;\r\n    /*box-shadow: 0 0 0 2px rgba(0,0,0,.1);*/\r\n    line-height: inherit;\r\n    background-color: rgba(158,158,158, 0.40);\r\n    /*background: #ffffff;*/\r\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _mapboxGl = require('mapbox-gl');

var _mapboxGl2 = _interopRequireDefault(_mapboxGl);

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _number_format = require('../modules/number_format');

var _tracking = require('../modules/tracking');

var _d3Scale = require('d3-scale');

var _mapFullextent = require('../modules/map-fullextent.js');

var _mapFullextent2 = _interopRequireDefault(_mapFullextent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'sc-map',
    watch: {
        'sharedState.selected': 'styleNeighborhoods',
        'sharedState.highlight': 'styleNeighborhoods',
        'sharedState.breaks': 'updateBreaks',
        'sharedState.year': 'updateYear',
        'sharedState.marker': 'createMarker',
        'sharedState.zoomNeighborhoods': 'zoomNeighborhoods',
        'privateState.isPitched3D': 'toggle3D'
    },
    methods: {
        initMap: function initMap() {
            var _this = this;
            _this.privateState.map = new _mapboxGl2.default.Map(_this.privateState.mapOptions);

            var map = _this.privateState.map;

            this.privateState.locationPopup = new _mapboxGl2.default.Popup({
                closeButton: true,
                closeOnClick: false
            });

            var nav = new _mapboxGl2.default.NavigationControl();
            map.addControl(nav, 'top-right');

            map.addControl(new _mapFullextent2.default({}), 'top-right');

            map.touchZoomRotate.disableRotation();

            map.on('style.load', function () {
                _axios2.default.get('data/geography.geojson.json').then(function (response) {
                    _this.privateState.mapLoaded = true;
                    _this.privateState.geoJSON = response.data;
                    _this.initNeighborhoods();
                    _this.styleNeighborhoods();
                    _this.initMapEvents();
                });
            });
        },
        toggle3D: function toggle3D() {
            var _this = this;
            var map = _this.privateState.map;
            var pitched = this.privateState.isPitched3D;

            if (pitched) {
                map.setLayoutProperty('neighborhoods', 'visibility', 'none');
                map.moveLayer('neighborhoods-fill-extrude');
                map.setPaintProperty("neighborhoods-fill-extrude", 'fill-extrusion-height', _this.getHeight());
            } else {
                map.setLayoutProperty('neighborhoods', 'visibility', 'visible');
                map.moveLayer('neighborhoods-fill-extrude', 'building');
                map.setPaintProperty("neighborhoods-fill-extrude", 'fill-extrusion-height', 0);
            }
        },
        initMapEvents: function initMapEvents() {
            var map = this.privateState.map;
            var _this = this;

            var popup = new _mapboxGl2.default.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('rotate', function (e) {
                if (map.getPitch() >= 20) {
                    _this.privateState.isPitched3D = true;
                } else {
                    _this.privateState.isPitched3D = false;
                }
            });

            map.on('click', function (e) {
                var features = map.queryRenderedFeatures(e.point, { layers: ['neighborhoods-fill-extrude'] });
                if (!features.length) {
                    return;
                }

                var feature = features[0];
                var featureIndex = _this.sharedState.selected.indexOf(feature.properties.id);

                if (featureIndex === -1) {
                    _this.sharedState.selected.push(feature.properties.id);
                } else {
                    _this.sharedState.selected.splice(featureIndex, 1);
                }

                (0, _tracking.replaceState)(_this.sharedState.metricId, _this.sharedState.selected);
            });

            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (!iOS) {
                map.on('mousemove', function (e) {
                    var features = map.queryRenderedFeatures(e.point, { layers: ['neighborhoods-fill-extrude'] });
                    map.getCanvas().style.cursor = features.length ? 'pointer' : '';

                    if (!features.length) {
                        popup.remove();
                        return;
                    }

                    var feature = features[0];
                    var id = feature.properties.id;
                    var data = _this.sharedState.metric.data.map[id]['y_' + _this.sharedState.year];
                    var val = (0, _number_format.prettyNumber)(data, _this.sharedState.metric.config.decimals, _this.sharedState.metric.config.prefix, _this.sharedState.metric.config.suffix);

                    popup.setLngLat(map.unproject(e.point)).setHTML('<div style="text-align: center; margin: 0; padding: 0;"><h3 style="font-size: 1.2em; margin: 0; padding: 0; line-height: 1em; font-weight: bold;">NPA ' + feature.properties.id + '</h3>' + val + '</div>').addTo(map);
                });
            }
        },
        initNeighborhoods: function initNeighborhoods() {
            var map = this.privateState.map;
            var _this = this;
            var geoJSON = _this.privateState.geoJSON;

            map.addSource('neighborhoods', {
                type: 'geojson',
                data: geoJSON
            });

            map.addLayer({
                'id': 'neighborhoods',
                'type': 'line',
                'source': 'neighborhoods',
                'layout': {},
                'paint': {}
            }, 'place_other');

            map.addLayer({
                'id': 'neighborhoods-fill-extrude',
                'type': 'fill-extrusion',
                'source': 'neighborhoods',

                'paint': {
                    'fill-extrusion-opacity': 1
                }
            }, 'building');

            map.addSource("markers", {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": []
                }
            });
            map.addLayer({
                "id": "markers",
                "type": "symbol",
                "source": "markers",
                "layout": {
                    "icon-image": "star-11",
                    "icon-size": 1.7
                }
            });
        },
        styleNeighborhoods: function styleNeighborhoods() {
            var map = this.privateState.map;
            var _this = this;

            map.setPaintProperty("neighborhoods-fill-extrude", 'fill-extrusion-color', _this.getColors());
            map.setPaintProperty("neighborhoods", 'line-color', _this.getOutlineColor());
            map.setPaintProperty("neighborhoods", 'line-width', _this.getOutlineWidth());
            if (_this.privateState.isPitched3D) {
                map.setPaintProperty("neighborhoods-fill-extrude", 'fill-extrusion-height', _this.getHeight());
            }
        },
        updateChoropleth: function updateChoropleth() {
            var _this = this;
            if (this.privateState.mapLoaded) {
                this.styleNeighborhoods();
            }
        },
        updateBreaks: function updateBreaks() {
            this.privateState.metricId = this.sharedState.metricId;
            this.updateChoropleth();
        },
        updateYear: function updateYear() {
            if (this.sharedState.metricId === this.privateState.metricId) {
                this.updateChoropleth();
            }
        },
        geoJSONMerge: function geoJSONMerge() {
            var _this = this;
            var geoObj = geojsonDataMerge(_this.privateState.geoJSON, _this.sharedState.metric.data.map, _this.sharedState.year);
            return geoObj;
        },
        zoomNeighborhoods: function zoomNeighborhoods() {
            var bounds = new _mapboxGl2.default.LngLatBounds();
            var _this = this;

            this.privateState.geoJSON.features.forEach(function (feature) {
                if (_this.sharedState.zoomNeighborhoods.indexOf(feature.properties.id) !== -1) {
                    feature.geometry.coordinates.forEach(function (coord) {
                        coord.forEach(function (el) {
                            bounds.extend(el);
                        });
                    });
                }
            });

            this.privateState.map.fitBounds(bounds, { padding: 100 });
        },

        createMarker: function createMarker() {
            var map = this.privateState.map;

            var theLabel = '' + this.sharedState.marker.label.replace(',', '<br />');
            var markers = {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [this.sharedState.marker.lng, this.sharedState.marker.lat]
                    },
                    "properties": {
                        "description": theLabel,
                        "type": "address"
                    }
                }]
            };
            map.getSource("markers").setData(markers);
        },
        getFullBounds: function getFullBounds() {
            var bounds = new _mapboxGl2.default.LngLatBounds();
            var _this = this;

            this.privateState.geoJSON.features.forEach(function (feature) {
                feature.geometry.coordinates.forEach(function (coord) {
                    coord.forEach(function (el) {
                        bounds.extend(el);
                    });
                });
            });

            return bounds;
        },
        getSelectedBounds: function getSelectedBounds() {
            var bounds = new _mapboxGl2.default.LngLatBounds();
            var _this = this;

            this.privateState.geoJSON.features.forEach(function (feature) {
                if (_this.sharedState.selected.indexOf(feature.properties.id) !== -1) {
                    feature.geometry.coordinates.forEach(function (coord) {
                        coord.forEach(function (el) {
                            bounds.extend(el);
                        });
                    });
                }
            });

            return bounds;
        },
        getOutlineColor: function getOutlineColor() {
            var stops = [];
            var _this = this;

            _this.sharedState.selected.forEach(function (id) {
                stops.push([id, '#ba00e4']);
            });

            var outline = {
                property: 'id',
                default: 'rgba(0,0,0,1)',
                type: 'categorical',
                stops: stops
            };

            if (stops.length > 0) {
                return outline;
            } else {
                return outline.default;
            }
        },
        getOutlineWidth: function getOutlineWidth() {
            var stops = [];
            var _this = this;

            _this.sharedState.selected.forEach(function (id) {
                stops.push([id, 4]);
            });

            var outlineSize = {
                property: 'id',
                default: 0.4,
                type: 'categorical',
                stops: stops
            };

            if (stops.length > 0) {
                return outlineSize;
            } else {
                return outlineSize.default;
            }

            return stops;
        },
        getColors: function getColors() {
            var stops = [];
            var _this = this;
            var data = _this.sharedState.metric.data.map;
            var breaks = this.sharedState.breaks;
            var colors = this.sharedState.colors;

            var color = function color(val) {
                if (val <= breaks[1]) {
                    return colors[0];
                } else if (val <= breaks[2]) {
                    return colors[1];
                } else if (val <= breaks[3]) {
                    return colors[2];
                } else if (val <= breaks[4]) {
                    return colors[3];
                } else if (val <= breaks[5]) {
                    return colors[4];
                }
            };

            Object.keys(data).forEach(function (id) {
                var value = data[id]['y_' + _this.sharedState.year];

                if (_this.sharedState.highlight.indexOf(id) !== -1) {
                    stops.push([id, '#F7E55B']);
                } else if (value !== null) {
                    stops.push([id, color(value)]);
                }
            });

            var fillColor = {
                property: 'id',
                default: 'rgb(242,243,240)',
                type: 'categorical',
                stops: stops
            };

            return fillColor;
        },
        getHeight: function getHeight() {
            var _this = this;
            var stops = [];
            var data = _this.sharedState.metric.data.map;

            var heightAdjust = (0, _d3Scale.scaleLinear)().domain([_this.sharedState.breaks[0], _this.sharedState.breaks[this.sharedState.breaks.length - 1]]).range([0, 3000]);

            Object.keys(data).forEach(function (id) {
                var value = data[id]['y_' + _this.sharedState.year];
                if (value !== null) {
                    stops.push([id, heightAdjust(value)]);
                }
            });

            var height = {
                property: 'id',
                default: 0,
                type: 'categorical',
                stops: stops
            };

            return height;
        }
    },

    mounted: function mounted() {
        this.initMap();
    }

};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
__vue__options__.staticRenderFns = [function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","width":"100%","height":"100%"}},[_c('div',{attrs:{"id":"map"}})])}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-30c72ddd", __vue__options__)
  } else {
    hotAPI.reload("data-v-30c72ddd", __vue__options__)
  }
})()}