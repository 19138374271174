var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert(".mdl-navigation__link[data-v-c618609a] {\r\n    padding: 5px 20px !important;\r\n}\r\n.mdl-navigation__link-end[data-v-c618609a] {\r\n    line-height: 1.3em;\r\n    padding: 8px 20px !important;\r\n}\r\n.mdl-navigation__link-bottom[data-v-c618609a] {\r\n    color: hsla(0,0%,100%,.75);\r\n    text-decoration: none;\r\n    display: inline;\r\n    font-size: 0.9em;\r\n}\r\n.navright[data-v-c618609a] {\r\n    padding: 0;\r\n    margin-right: 15px !important;\r\n    position: absolute;\r\n    right: 0;\r\n}\r\n.navleft[data-v-c618609a] {\r\n    margin-right: 32px;\r\n}\r\n.sidebar-title[data-v-c618609a] {\r\n    padding: 0 20px 8px;\r\n}\r\n.icon[data-v-c618609a] {\r\n    width: 24px;\r\n    height: 24px;\r\n}\n.mdl-navigation__link-bottom {\r\n    color: hsla(0,0%,100%,.75);\r\n    text-decoration: none;\r\n    display: inline;\r\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _fetch = require('../modules/fetch');

var _fetch2 = _interopRequireDefault(_fetch);

var _tracking = require('../modules/tracking');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'sc-sidenav',
    methods: {
        changeFilter: function changeFilter(filter) {
            this.privateState.filterVal = filter;
        },
        hideOverlay: function hideOverlay() {
            var drawer = document.querySelector('.mdl-layout__drawer');
            if (drawer) {
                drawer.classList.remove('is-visible');
                document.querySelector('.mdl-layout__obfuscator').classList.remove('is-visible');
            }
        },
        changeMetric: function changeMetric(metric) {
            this.hideOverlay();
            if (this.sharedState.metricId !== metric) {
                (0, _tracking.replaceState)(metric, this.sharedState.selected);
                (0, _tracking.gaEvent)('metric', this.privateState.data['m' + metric].title.trim(), this.privateState.data['m' + metric].category.trim());
                (0, _fetch2.default)(this.sharedState, metric);
            }
        },
        filterCategories: function filterCategories(value) {
            var categories = [];
            for (var key in value) {
                if (categories.indexOf(value[key].category) === -1) {
                    categories.push(value[key].category);
                }
            }
            return categories;
        },
        filterMetrics: function filterMetrics(value, filter) {
            var metrics = [];
            for (var key in value) {
                if (value[key].category === filter) {
                    metrics.push({ title: value[key].title, metric: value[key].metric });
                }
            }
            return metrics;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"demo-navigation mdl-navigation mdl-color--blue-grey-900"},[(_vm.privateState.filterVal)?[_c('a',{staticClass:"mdl-navigation__link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.changeFilter(null)}}},[_c('svg',{staticClass:"mdl-color-text--blue-grey-400 icon icon-keyboard_arrow_left navleft"},[_c('use',{attrs:{"xlink:href":"#icon-keyboard_arrow_left"}})]),_vm._v("Back")]),_vm._v(" "),_vm._l((_vm.filterMetrics(_vm.privateState.data, _vm.privateState.filterVal)),function(m){return [_c('a',{staticClass:"mdl-navigation__link mdl-navigation__link-end",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.changeMetric(m.metric)}}},[_vm._v(_vm._s(m.title))])]})]:[_c('span',{staticClass:"sidebar-title"},[_vm._v("Explore the Data")]),_vm._v(" "),_vm._l((_vm.filterCategories(_vm.privateState.data)),function(category){return [_c('a',{staticClass:"mdl-navigation__link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.changeFilter(category)}}},[_vm._v(_vm._s(category)),_c('svg',{staticClass:"mdl-color-text--blue-grey-400 icon icon-keyboard_arrow_right navright"},[_c('use',{attrs:{"xlink:href":"#icon-keyboard_arrow_right"}})])])]}),_vm._v(" "),_c('a',{staticClass:"mdl-navigation__link",attrs:{"href":"http://qol.charmeck.org/"}},[_vm._v("About")]),_vm._v(" "),_c('a',{staticClass:"mdl-navigation__link",attrs:{"onclick":"ga('send', 'event', 'download', 'metric zip file download')","href":"downloads/qol-data.zip"}},[_vm._v("Download Data")])]],2)}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-c618609a"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-c618609a", __vue__options__)
  } else {
    hotAPI.reload("data-v-c618609a", __vue__options__)
  }
})()}