var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert(".mdl-tabs__tab[data-v-a9e2df66] {\n  padding: 0 14px;\n}\n.mdl-tabs__panel[data-v-a9e2df66] {\n  padding: 10px;\n}\n.mdl-tabs__tab-bar[data-v-a9e2df66] {\n  justify-content: left;\n}\n.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active[data-v-a9e2df66]:after {\n  background: #ff4081;\n  height: 4px;\n}\n.mdl-chip[data-v-a9e2df66] {\n  margin: 2px;\n  cursor: pointer;\n}\n.mdl-chip.is-active[data-v-a9e2df66] {\n  background: #ff4081;\n  color: white;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);\n}\n\n@media (max-width: 975px) {\n  .category-tabs[data-v-a9e2df66] {\n    display: none;\n  }\n}\n.mdl-tabs__panel {\n  display: none;\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _tracking = require('../modules/tracking');

var _fetch = require('../modules/fetch');

var _fetch2 = _interopRequireDefault(_fetch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'tabs',
  methods: {
    changeMetric: function changeMetric(metric) {
      if (this.sharedState.metricId !== metric) {
        (0, _tracking.replaceState)(metric, this.sharedState.selected);
        (0, _tracking.gaEvent)('metric', this.privateState.data['m' + metric].title.trim(), this.privateState.data['m' + metric].category.trim());
        (0, _fetch2.default)(this.sharedState, metric);
      }
    },
    filterCategories: function filterCategories(value) {
      var categories = [];
      for (var key in value) {
        if (categories.indexOf(value[key].category) === -1) {
          categories.push(value[key].category);
        }
      }
      return categories;
    },
    filterMetrics: function filterMetrics(value, filter) {
      var metrics = [];
      for (var key in value) {
        if (value[key].category === filter) {
          metrics.push({ title: value[key].title, metric: value[key].metric });
        }
      }
      return metrics;
    },
    tabId: function tabId(id) {
      var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      return prefix + 'tab' + id + '-panel';
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-color--white category-tabs no-print"},[_c('div',{staticClass:"mdl-tabs mdl-js-tabs"},[_c('div',{staticClass:"mdl-tabs__tab-bar mdl-typography--text--center"},[_vm._l((_vm.filterCategories(_vm.privateState.data)),function(category,index){return [_c('a',{class:['mdl-tabs__tab', category === _vm.privateState.data[("m" + (_vm.sharedState.metricId))].category ? 'is-active' : ''],attrs:{"href":_vm.tabId(index, '#')}},[_vm._v(_vm._s(category))])]})],2),_vm._v(" "),_vm._l((_vm.filterCategories(_vm.privateState.data)),function(category,index){return [_c('div',{class:['mdl-tabs__panel', category === _vm.privateState.data[("m" + (_vm.sharedState.metricId))].category ? 'is-active': ''],attrs:{"id":_vm.tabId(index)}},[_vm._l((_vm.filterMetrics(_vm.privateState.data, category)),function(m){return [_c('button',{class:['mdl-chip', m.metric === _vm.sharedState.metricId ? 'is-active' : ''],attrs:{"type":"button"},on:{"click":function($event){_vm.changeMetric(m.metric)}}},[_c('span',{staticClass:"mdl-chip__text"},[_vm._v(_vm._s(m.title))])])]})],2)]})],2)])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-a9e2df66"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-a9e2df66", __vue__options__)
  } else {
    hotAPI.reload("data-v-a9e2df66", __vue__options__)
  }
})()}