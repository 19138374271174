var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert(".offline[data-v-e5c84338] {\n    padding: 20px;\n    color: #fff;\n    background: #7B1FA2;\n}\n.offline h3[data-v-e5c84338] {\n    margin: 0;\n    font-size: 1.5em;\n    font-weight: bold;\n    color: white;\n}\n.offline p[data-v-e5c84338] {\n    margin-bottom: 0;\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'offline',
    data: function data() {
        return {
            online: true
        };
    },
    mounted: function mounted() {
        var _this = this;
        if ('onLine' in navigator) {
            if (navigator.onLine === false) {
                _this.online = false;
            }
            window.addEventListener('online', _this.onLine);
            window.addEventListener('offline', _this.offLine);
        }
    },
    methods: {
        onLine: function onLine() {
            this.online = true;
        },
        offLine: function offLine() {
            this.online = false;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.online),expression:"!online"}],staticClass:"offline mdl-shadow--2dp mdl-cell mdl-cell--12-col no-print"},[_c('h3',[_vm._v("You are currently offline!")]),_vm._v(" "),_c('p',[_vm._v("Some things may not work until you reconnect.")])])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-e5c84338"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-e5c84338", __vue__options__)
  } else {
    hotAPI.reload("data-v-e5c84338", __vue__options__)
  }
})()}