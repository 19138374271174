var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert(".embedcode {\r\n  padding: 15px;\r\n  width: 100%;\r\n}\r\n.embedcode div {\r\n  width: 100%;\r\n}\r\n.embedcode h3 {\r\n  font-size: 1.2em;\r\n  margin: 0 0 10px;\r\n  line-height: 1em;\r\n}\r\n.embedcode #embed-textarea .mdl-textfield__label,\r\n.embedcode #embed-title .mdl-textfield__label {\r\n  top: 4px;\r\n  color: #3f51b5;\r\n  font-size: 12px;\r\n}\r\n.embedcode #embed-textarea .mdl-textfield__input {\r\n  font-size: 12px;\r\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'sc-embedcode',
    watch: {
        'sharedState.metric.config': 'updateTitle'
    },
    methods: {
        updateTitle: function updateTitle() {
            this.privateState.title = this.sharedState.metric.config.title;
        },
        createIframe: function createIframe() {
            var iframe = '<iframe src="' + this.privateState.qolembedURL + 'embed.html?m=' + this.sharedState.metricId + '&y=' + this.sharedState.year + '&s=' + this.sharedState.selected.join(',') + '&t=' + encodeURIComponent(this.privateState.title) + '" style="width: 500px; height: 500px; border: 1px solid #595959"></iframe>';
            return iframe;
        }
    },
    filters: {
        createIframe: function createIframe() {
            var iframe = '<iframe src="' + this.privateState.qolembedURL + 'embed.html?m=' + this.sharedState.metricId + '&y=' + this.sharedState.year + '&s=' + this.sharedState.selected.join(',') + '&t=' + encodeURIComponent(this.privateState.title) + '" style="width: 500px; height: 500px; border: 1px solid #595959"></iframe>';
            return iframe;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.privateState.qolembedURL),expression:"privateState.qolembedURL"}],staticClass:"mdl-typography--text-center mdl-color--white mdl-shadow--2dp mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet flexcontainer"},[(_vm.sharedState.metric.config)?_c('div',{staticClass:"embedcode"},[_c('h3',[_vm._v("Embed This Map")]),_vm._v(" "),_c('div',{staticClass:"mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-focused",attrs:{"id":"embed-title"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.privateState.title),expression:"privateState.title"}],staticClass:"mdl-textfield__input",attrs:{"type":"text","id":"embedTitle","autocomplete":"off"},domProps:{"value":(_vm.privateState.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.privateState, "title", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"mdl-textfield__label",attrs:{"for":"embedTitle"}},[_vm._v("Map Title")])]),_vm._v(" "),_c('div',{staticClass:"mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-focused",attrs:{"id":"embed-textarea"}},[_c('textarea',{staticClass:"mdl-textfield__input",attrs:{"type":"text","rows":"5","id":"embedIframeCode","onclick":"this.select()","autocomplete":"off"},on:{"keypress":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v(_vm._s(_vm.createIframe(_vm.privateState.title)))]),_vm._v(" "),_c('label',{staticClass:"mdl-textfield__label",attrs:{"for":"embedIframeCode"}},[_vm._v("Drop this in your web page")])])]):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-ae50cdf2", __vue__options__)
  } else {
    hotAPI.reload("data-v-ae50cdf2", __vue__options__)
  }
})()}