var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert("ul[data-v-1c59e050] {\r\n    list-style-type: none;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\nli[data-v-1c59e050] {\r\n    cursor: pointer;\r\n    display: inline-block;\r\n    padding-right: 10px;\r\n    transition: all 0.2s ease-in;\r\n}\r\nli[data-v-1c59e050]:hover {\r\n    text-shadow: 1px 0px 20px #ff4081;\r\n}\r\n.selected[data-v-1c59e050] {\r\n    color: rgb(211, 45, 101);\r\n    font-weight: bold;\r\n}\r\n.clearer[data-v-1c59e050] {\r\n    height: 0;\r\n    clear: both;\r\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'sc-years',
    methods: {
        setYear: function setYear(year) {
            this.sharedState.year = year;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.sharedState.metric.years.length > 1),expression:"sharedState.metric.years.length > 1"}]},[_vm._l((_vm.sharedState.metric.years),function(y){return [(y == _vm.sharedState.year)?_c('li',{staticClass:"selected"},[_vm._v(_vm._s(y))]):_c('li',{on:{"click":function($event){_vm.setYear(y)}}},[_vm._v(_vm._s(y))])]})],2),_vm._v(" "),_c('div',{staticClass:"clear:both"})])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-1c59e050"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1c59e050", __vue__options__)
  } else {
    hotAPI.reload("data-v-1c59e050", __vue__options__)
  }
})()}