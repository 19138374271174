var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert("#metadata .meta {\r\n    padding: 0 20px 20px;\r\n}\r\n#metadata .meta h3 {\r\n    font-size: 2em;\r\n}\r\n#metadata .meta h4 {\r\n    font-size: 1.5em;\r\n}\r\n#metadata .meta-side {\r\n    align-items: flex-start;\r\n    align-content: flex-start;\r\n}\r\n#metadata table {\r\n    width: 100%;\r\n    white-space: normal;\r\n}\r\n#metadata table:first-of-type {\r\n    margin-bottom: 15px;\r\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _meta = require('../modules/meta.js');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'sc-metadata',
    methods: {
        important: function important(data) {
            return (0, _meta.metaImportant)(data);
        },
        about: function about(data) {
            return (0, _meta.metaAbout)(data);
        },
        resources: function resources(data) {
            return (0, _meta.metaResources)(data);
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sharedState.metadata)?_c('div',{attrs:{"id":"metadata"}},[_c('div',{staticClass:"mdl-grid"},[_c('div',{staticClass:"mdl-shadow--2dp mdl-color--white mdl-cell mdl-cell--8-col meta"},[_c('h3',[_vm._v("Why This is Important")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.important(_vm.sharedState.metadata))}}),_vm._v(" "),_c('h4',[_vm._v("Additional Resources")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.resources(_vm.sharedState.metadata))}})]),_vm._v(" "),_c('div',{staticClass:"mdl-cell mdl-cell--4-col mdl-cell--8-col-tablet mdl-grid mdl-grid--no-spacing meta-side"},[_c('div',{staticClass:"mdl-shadow--2dp mdl-color--white mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop meta"},[_c('h3',[_vm._v("About the Data")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.about(_vm.sharedState.metadata))}})])])])]):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5749b804", __vue__options__)
  } else {
    hotAPI.reload("data-v-5749b804", __vue__options__)
  }
})()}