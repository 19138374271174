var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert(".icon[data-v-13451a08] {\n    width: 24px;\n    height: 24px;\n}\n.icon-twitter-with-circle[data-v-13451a08] {\n    fill: #1DA1F2;\n}\n.icon-facebook-with-circle[data-v-13451a08] {\n    fill: #4E71A8;\n}\n.icon-linkedin-with-circle[data-v-13451a08] {\n    fill: #1686B0;\n}\n.icon-brand-github[data-v-13451a08] {\n    fill: #0F0F0F;\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'sc-social',
    methods: {
        social: function social(socialType) {
            var _this = this;
            var pageUrl = encodeURIComponent(document.URL);
            if (socialType === 'twitter') {
                var socialUrl = 'https://twitter.com/intent/tweet?url=' + pageUrl + '&text=' + encodeURIComponent(_this.sharedState.metric.config.title);
                window.open(socialUrl, "Share");
            }
            if (socialType === 'facebook') {
                var _socialUrl = 'https://www.facebook.com/sharer.php?u=' + pageUrl;
                window.open(_socialUrl, "Share");
            }
            if (socialType === 'linkedin') {
                var _socialUrl2 = 'https://www.linkedin.com/shareArticle?url=' + pageUrl;
                window.open(_socialUrl2, "Share");
            }
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-muted"},[_c('a',{attrs:{"href":"javascript:void(0)","aria-label":"Share on Twitter"},on:{"click":function($event){_vm.social('twitter')}}},[_c('svg',{staticClass:"icon icon-twitter-with-circle"},[_c('use',{attrs:{"xlink:href":"#icon-twitter-with-circle"}})])]),_vm._v(" "),_c('a',{attrs:{"href":"javascript:void(0)","aria-label":"Share on Facebook"},on:{"click":function($event){_vm.social('facebook')}}},[_c('svg',{staticClass:"icon icon-facebook-with-circle"},[_c('use',{attrs:{"xlink:href":"#icon-facebook-with-circle"}})])]),_vm._v(" "),_c('a',{attrs:{"href":"javascript:void(0)","aria-label":"Share on LinkedIn"},on:{"click":function($event){_vm.social('linkedin')}}},[_c('svg',{staticClass:"icon icon-linkedin-with-circle"},[_c('use',{attrs:{"xlink:href":"#icon-linkedin-with-circle"}})])]),_vm._v(" "),_c('a',{attrs:{"href":"https://github.com/tobinbradley/quality-of-life-dashboard","aria-label":"View project on Github"}},[_c('svg',{staticClass:"icon icon-brand-github"},[_c('use',{attrs:{"xlink:href":"#icon-brand-github"}})])])])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-13451a08"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-13451a08", __vue__options__)
  } else {
    hotAPI.reload("data-v-13451a08", __vue__options__)
  }
})()}