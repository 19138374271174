;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _social = require('./social.vue');

var _social2 = _interopRequireDefault(_social);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'sc-footer',
    components: {
        social: _social2.default
    },
    methods: {
        social: function social(socialType) {
            var _this = this;
            var pageUrl = encodeURIComponent(document.URL);
            if (socialType === 'twitter') {
                var socialUrl = 'https://twitter.com/intent/tweet?url=' + pageUrl + '&text=' + encodeURIComponent(_this.sharedState.metric.config.title);
                window.open(socialUrl, "Share");
            }
            if (socialType === 'facebook') {
                var _socialUrl = 'https://www.facebook.com/sharer.php?u=' + pageUrl;
                window.open(_socialUrl, "Share");
            }
            if (socialType === 'linkedin') {
                var _socialUrl2 = 'https://www.linkedin.com/shareArticle?url=' + pageUrl;
                window.open(_socialUrl2, "Share");
            }
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"mdl-grid"},[_c('div',{staticClass:"mdl-typography--text-center mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet"},[_vm._m(0),_vm._v(" "),(_vm.privateState.links)?_c('p',{staticClass:"site-links"},_vm._l((_vm.privateState.links),function(link){return _c('a',{attrs:{"href":link.href,"target":"_blank"}},[_vm._v(_vm._s(link.text))])})):_vm._e(),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('social')],1)])}
__vue__options__.staticRenderFns = [function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":"img/seals.png","width":"177","height":"36","alt":"City and County Seals"}})])},function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-muted"},[_vm._v("\n            Created by "),_c('a',{attrs:{"href":"http://charmeck.org/mecklenburg/county/Pages/Default.aspx"}},[_vm._v("Mecklenburg County")]),_vm._v(", the "),_c('a',{attrs:{"href":"http://charmeck.org/city/charlotte/Pages/default.aspx"}},[_vm._v("City of Charlotte")]),_vm._v(", and "),_c('a',{attrs:{"href":"http://www.uncc.edu/"}},[_vm._v("UNC Charlotte")]),_vm._v("\n            with much ♥ for the projects that make this site possible:\n            "),_c('br'),_vm._v(" "),_c('a',{attrs:{"href":"https://www.openstreetmap.org/","target":"_blank"}},[_vm._v("OpenStreetMap")]),_vm._v(",\n            "),_c('a',{attrs:{"href":"http://osm2vectortiles.org/","target":"_blank"}},[_vm._v("OSM2VectorTiles")]),_vm._v(",\n            "),_c('a',{attrs:{"href":"https://www.mapbox.com/mapbox-gl-js/api/","target":"_blank"}},[_vm._v("Mapbox GL JS")]),_vm._v(",\n            "),_c('a',{attrs:{"href":"http://vuejs.org/","target":"_blank"}},[_vm._v("Vue.js")]),_vm._v(", and\n            "),_c('a',{attrs:{"href":"http://getmdl.io/","target":"_blank"}},[_vm._v("Material Design Lite")]),_vm._v(".\n        ")])}]
__vue__options__._scopeId = "data-v-8b856bac"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-8b856bac", __vue__options__)
  } else {
    hotAPI.reload("data-v-8b856bac", __vue__options__)
  }
})()}