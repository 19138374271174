var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert("/* selected */    \n.ct-distributionchart .ct-point {\n    stroke: #ba00e4;\n    stroke-width: 7;\n}\n\n/* distribution series */\n.ct-distributionchart .ct-area {\n    fill-opacity: 1;\n}\n.ct-distributionchart .ct-series-a .ct-line, .ct-distributionchart .ct-series-a .ct-area {\n    stroke: rgb(238,250,227);\n    fill: rgb(238,250,227);\n}\n.ct-distributionchart .ct-series-b .ct-line, .ct-distributionchart .ct-series-b .ct-area {\n    stroke: rgb(186,228,188);\n    fill: rgb(186,228,188);\n}\n.ct-distributionchart .ct-series-c .ct-line, .ct-distributionchart .ct-series-c .ct-area {\n    stroke: rgb(123,204,196);\n    fill: rgb(123,204,196);\n}\n.ct-distributionchart .ct-series-d .ct-line, .ct-distributionchart .ct-series-d .ct-area {\n    stroke: rgb(67,162,202);\n    fill: rgb(67,162,202);\n}\n.ct-distributionchart .ct-series-e .ct-line, .ct-distributionchart .ct-series-e .ct-area {\n    stroke: rgb(8,104,172);\n    fill: rgb(8,104,172);\n}\n.ct-distributionchart .ct-series-f .ct-line {\n    stroke: #666;\n    stroke-dasharray: 5, 2;\n    stroke-width: 2;\n}\nh1[data-v-58a0899b] {\n    font-size: 1.1em;\n    margin: 15px 0 0;\n}\nspan[data-v-58a0899b] {\n    font-size: 0.8em;\n}    \n.legend[data-v-58a0899b] {\n    font-size: 1.2em;\n}\n.legend-selected[data-v-58a0899b] {\n    color: #ba00e4;\n}\n.legend-median[data-v-58a0899b] {\n    fill: #666;\n    width: 14px;\n    height: 14px;\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _chartist = require('chartist');

var _chartist2 = _interopRequireDefault(_chartist);

var _isnumeric = require('../modules/isnumeric');

var _isnumeric2 = _interopRequireDefault(_isnumeric);

var _number_format = require('../modules/number_format');

var _metric_calculations = require('../modules/metric_calculations');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'sc-distributionchart',
    watch: {
        'sharedState.metric.data': 'renderChart',
        'sharedState.selected': 'renderChart',
        'sharedState.year': 'renderChart'
    },
    methods: {
        renderChart: function renderChart() {
            var data = this.updateData();
            var _this = this;

            var options = {
                showLine: false,
                showPoint: false,
                showArea: true,
                fullWidth: true,
                height: '160px',
                chartPadding: {
                    bottom: 0
                },
                axisX: {
                    labelInterpolationFnc: function labelInterpolationFnc(value, index) {
                        return null;
                    }
                },
                axisY: {
                    labelInterpolationFnc: function labelInterpolationFnc(value, index) {
                        return (0, _number_format.abbrNum)((0, _number_format.round)(Number(value), 2), 2);
                    }
                },
                series: {
                    'series-selected': {
                        showLine: false,
                        showPoint: true,
                        showArea: false
                    },
                    'series-median': {
                        showLine: true,
                        showPoint: false,
                        showArea: false
                    }
                }
            };

            this.privateState.chart = new _chartist2.default.Line('.ct-distributionchart', data, options);
        },
        updateData: function updateData() {
            var chartData = {
                labels: [],
                series: []
            };
            var _this = this;
            var metric = this.sharedState.metric;

            var data = this.dataToSortedArray(this.sharedState.metric.data.map, this.sharedState.year);
            var med = (0, _metric_calculations.median)(data.map(function (el) {
                return el.val;
            }));
            _this.privateState.median = (0, _number_format.prettyNumber)(med, metric.config.decimals, metric.config.prefix, metric.config.suffix);

            var dataArrayA = [];
            var dataArrayB = [];
            var dataArrayC = [];
            var dataArrayD = [];
            var dataArrayE = [];
            var dataArrayMedian = [];
            var dataArraySelected = [];

            for (var i = 0; i < data.length; i++) {
                chartData.labels.push(data[i].id);

                if (_this.sharedState.selected.indexOf(data[i].id) !== -1) {
                    dataArraySelected.push(data[i].val);
                } else {
                    dataArraySelected.push(null);
                }

                if (i === 0 || i === data.length - 1) {
                    dataArrayMedian.push(med);
                } else {
                    dataArrayMedian.push(med);
                }

                if (data[i].val <= _this.sharedState.breaks[1]) {
                    dataArrayA.push(data[i].val);
                    dataArrayB.push(null);
                    dataArrayC.push(null);
                    dataArrayD.push(null);
                    dataArrayE.push(null);
                } else if (data[i].val <= _this.sharedState.breaks[2]) {
                    dataArrayB.push(data[i].val);
                    dataArrayA.push(null);
                    dataArrayC.push(null);
                    dataArrayD.push(null);
                    dataArrayE.push(null);
                } else if (data[i].val <= _this.sharedState.breaks[3]) {
                    dataArrayC.push(data[i].val);
                    dataArrayB.push(null);
                    dataArrayA.push(null);
                    dataArrayD.push(null);
                    dataArrayE.push(null);
                } else if (data[i].val <= _this.sharedState.breaks[4]) {
                    dataArrayD.push(data[i].val);
                    dataArrayB.push(null);
                    dataArrayC.push(null);
                    dataArrayA.push(null);
                    dataArrayE.push(null);
                } else {
                    dataArrayE.push(data[i].val);
                    dataArrayB.push(null);
                    dataArrayC.push(null);
                    dataArrayD.push(null);
                    dataArrayA.push(null);
                }
            }
            chartData.series.push({ name: 'series-1', data: dataArrayA });
            chartData.series.push({ name: 'series-2', data: dataArrayB });
            chartData.series.push({ name: 'series-3', data: dataArrayC });
            chartData.series.push({ name: 'series-4', data: dataArrayD });
            chartData.series.push({ name: 'series-5', data: dataArrayE });
            chartData.series.push({ name: 'series-median', data: dataArrayMedian });
            chartData.series.push({ name: 'series-selected', data: dataArraySelected });

            return chartData;
        },
        dataToSortedArray: function dataToSortedArray(data, year) {
            var dataArray = [];
            var keys = Object.keys(data);

            for (var i = 0; i < keys.length; i++) {
                if ((0, _isnumeric2.default)(data[keys[i]]['y_' + year])) {
                    dataArray.push({ "id": keys[i], "val": data[keys[i]]['y_' + year] });
                }
            }

            dataArray.sort(function (a, b) {
                return a.val - b.val;
            });

            this.privateState.chartData = dataArray;

            return dataArray;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qol-chart mdl-color--white mdl-shadow--2dp mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"},[_c('div',{staticClass:"scatterplot mdl-typography--text-center"},[_c('h1',[_vm._v("Data Distribution, "+_vm._s(_vm.sharedState.year))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.sharedState.selected.length > 0),expression:"sharedState.selected.length > 0"}]},[_c('svg',{staticClass:"icon legend legend-selected"},[_c('use',{attrs:{"xlink:href":"#icon-lens"}})]),_vm._v(" Selected")]),_vm._v(" "),_c('span',[_c('svg',{staticClass:"icon legend legend-median"},[_c('use',{attrs:{"xlink:href":"#icon-more_horiz"}})]),_vm._v(" Median "+_vm._s(_vm.privateState.median))]),_vm._v(" "),_c('div',{staticClass:"ct-distributionchart"})])])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-58a0899b"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-58a0899b", __vue__options__)
  } else {
    hotAPI.reload("data-v-58a0899b", __vue__options__)
  }
})()}