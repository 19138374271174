var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert("#years[data-v-08aee517] {\r\n    color: rgb(210, 210, 210);\r\n    padding: 5px;\r\n    display: inline-block;\r\n    width: 100%;\r\n}\r\n.flex-container[data-v-08aee517] {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.flex-left[data-v-08aee517], .flex-right[data-v-08aee517] {\r\n    width: 20px;\r\n}\r\n.flex-center[data-v-08aee517] {\r\n    flex-grow: 1;\r\n    padding: 0 10px;\r\n}\r\n#yearslider[data-v-08aee517] {\r\n  width: 100%;\r\n}\r\nh3[data-v-08aee517] {\r\n    margin: 0;\r\n    font-size: 1.1em;\r\n}\r\n\r\n.playpause label[data-v-08aee517] {\r\n  display: block;\r\n  box-sizing: border-box;\r\n  width: 0;\r\n  height: 34px;\r\n  border-color: transparent transparent transparent rgb(158, 158, 158);\r\n  transition: 180ms all ease;\r\n  cursor: pointer;\r\n  border-style: double;\r\n  border-width: 0px 0 0px 25px;\r\n}\r\n.playpause label[data-v-08aee517]:hover {\r\n  border-color: transparent transparent transparent #ff4081;\r\n}\r\n.playpause input[type=\"checkbox\"][data-v-08aee517] {\r\n  display: none;\r\n}\r\n.playpause input[type=\"checkbox\"]:checked + label[data-v-08aee517] {\r\n  border-style: solid;\r\n  border-width: 17px 0 17px 25px;\r\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'sc-year',
    methods: {
        changeYear: function changeYear(elem) {
            var closest = this.getClosest(this.sharedState.metric.years, elem.srcElement.value);
            this.sharedState.year = elem.srcElement.value = closest;
        },
        getClosest: function getClosest(arr, val) {
            return arr.reduce(function (prev, curr) {
                return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
            });
        },
        play: function play($event) {
            var _this = this;
            var checked = $event.target.checked;

            if (!checked) {
                var i = _this.sharedState.metric.years.indexOf(_this.sharedState.year) + 1;
                i >= _this.sharedState.metric.years.length ? i = 0 : null;
                _this.sharedState.year = _this.sharedState.metric.years[i];

                _this.privateState.playToggle = setInterval(function () {
                    i++;
                    if (i >= _this.sharedState.metric.years.length) {
                        i = 0;
                    }
                    _this.sharedState.year = _this.sharedState.metric.years[i];
                }, 1500);
            } else {
                clearInterval(_this.privateState.playToggle);
            }
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"years"}},[_c('div',{staticClass:"flex-container"},[(_vm.sharedState.metric.years.length > 1)?_c('div',{staticClass:"flex-left"},[_c('div',{staticClass:"playpause"},[_c('input',{attrs:{"type":"checkbox","value":"None","id":"playpause","name":"check","checked":""},on:{"change":_vm.play}}),_vm._v(" "),_c('label',{attrs:{"for":"playpause"}})])]):_vm._e(),_vm._v(" "),(_vm.sharedState.metric.years.length > 1)?_c('div',{staticClass:"flex-center"},[_c('input',{attrs:{"id":"yearslider","type":"range","min":_vm.sharedState.metric.years[0],"max":_vm.sharedState.metric.years[_vm.sharedState.metric.years.length - 1],"step":"1","list":"ticks"},domProps:{"value":_vm.sharedState.year},on:{"change":_vm.changeYear}}),_vm._v(" "),_c('datalist',{attrs:{"id":"ticks"}},_vm._l((_vm.sharedState.metric.years),function(year){return _c('option',[_vm._v("\n                    "+_vm._s(year)+"\n                ")])}))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-right"},[_c('h3',[_vm._v(_vm._s(_vm.sharedState.year))])])])])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-08aee517"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-08aee517", __vue__options__)
  } else {
    hotAPI.reload("data-v-08aee517", __vue__options__)
  }
})()}