var __vueify_style_dispose__ = require("vueify/lib/insert-css").insert(".qol-chart .ct-series-b .ct-line,\n.qol-chart .ct-series-b .ct-point {\n    stroke: #ba00e4;\n}\n.qol-chart .ct-series-a .ct-line,\n.qol-chart .ct-series-a .ct-point {\n    stroke: orange;\n}\n.ct-trendchart {\n    margin-left: 20px;\n}\n.ct-axis-title {\n    font-size: 10px;\n    fill: rgba(0, 0, 0, 0.6);\n}\n.chartist-tooltip {\n    position: absolute;\n    display: inline-block;\n    opacity: 0;\n    min-width: 5em;\n    padding: .5em;\n    background: rgba(0, 0, 0, 0.85);\n    color: #ccc;\n    font-family: Oxygen, Helvetica, Arial, sans-serif;\n    font-weight: 700;\n    text-align: center;\n    pointer-events: none;\n    z-index: 1;\n    border-radius: 5px;\n    transition: opacity .2s linear;\n}\n.chartist-tooltip:before {\n    content: \"\";\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    width: 0;\n    height: 0;\n    margin-left: -15px;\n    border: 15px solid transparent;\n    border-top-color: rgba(0, 0, 0, 0.85);\n}\n.chartist-tooltip.tooltip-show {\n    opacity: 1;\n}\n.ct-area,\n.ct-line {\n    pointer-events: none;\n}\nh1[data-v-4dd67e70] {\n    font-size: 1.1em;\n    margin: 15px 0 0;\n}\nspan.legend[data-v-4dd67e70] {\n    font-size: 0.8em;\n}\n.icon[data-v-4dd67e70] {\n    vertical-align: middle;\n    width: 1.5em;\n    height: 1.5em;\n}\n.legend-selected[data-v-4dd67e70] {\n    color: #ba00e4;\n}\n.legend-county[data-v-4dd67e70] {\n    color: orange;\n}")
;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _chartist = require('chartist');

var _chartist2 = _interopRequireDefault(_chartist);

var _metric_calculations = require('../modules/metric_calculations');

var _number_format = require('../modules/number_format');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

require('../modules/chartist.axis.title.js');
require('../modules/chartist.tooltip.js');
exports.default = {
    name: 'sc-trendchart',
    watch: {
        'sharedState.metric.data': 'renderChart',
        'sharedState.selected': 'renderChart'
    },
    methods: {
        renderChart: function renderChart() {
            if (this.sharedState.metric.years.length > 1) {
                var _this = this;
                var data = this.updateData();
                var options = {
                    fullWidth: true,
                    height: '180px',
                    showArea: false,
                    low: 0,
                    chartPadding: {
                        right: 40
                    },
                    lineSmooth: _chartist2.default.Interpolation.cardinal({
                        fillHoles: true
                    }),
                    axisY: {
                        labelInterpolationFnc: function labelInterpolationFnc(value, index) {
                            return (0, _number_format.abbrNum)((0, _number_format.round)(Number(value), 2), 2);
                        }
                    },
                    axisX: {
                        labelInterpolationFnc: function labelInterpolationFnc(value, index) {
                            var len = _this.sharedState.metric.years[_this.sharedState.metric.years.length - 1] - _this.sharedState.metric.years[0];
                            if (len > 6) {
                                return index % 2 === 0 ? value : null;
                            } else {
                                return value;
                            }
                        }
                    },
                    plugins: [_chartist2.default.plugins.tooltip({
                        transformTooltipTextFnc: function transformTooltipTextFnc(value) {
                            return (0, _number_format.prettyNumber)(value, _this.sharedState.metric.config.decimals, _this.sharedState.metric.config.prefix, _this.sharedState.metric.config.suffix);
                        }
                    })]
                };

                if (_this.sharedState.metric.config.label) {
                    options.plugins.push(_chartist2.default.plugins.ctAxisTitle({
                        axisX: {
                            axisTitle: '',
                            axisClass: 'ct-axis-title',
                            offset: {
                                x: 0,
                                y: 50
                            },
                            textAnchor: 'middle'
                        },
                        axisY: {
                            axisTitle: _this.sharedState.metric.config.label,
                            axisClass: 'ct-axis-title',
                            offset: {
                                x: 0,
                                y: -1
                            },
                            flipTitle: false,
                            textAnchor: 'middle'
                        }
                    }));
                }

                if (this.sharedState.metric.config.suffix && this.sharedState.metric.config.suffix === '%') {
                    options.high = 100;
                }
                this.privateState.chart = new _chartist2.default.Line('.ct-trendchart', data, options);

                this.privateState.chart.on('draw', function (data) {
                    if (data.type === 'line') {
                        data.element.animate({
                            d: {
                                begin: 500 * data.index,
                                dur: 500,
                                from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                                to: data.path.clone().stringify(),
                                easing: _chartist2.default.Svg.Easing.easeOutQuint
                            },
                            opacity: {
                                begin: 500 * data.index,
                                dur: 500,
                                from: 0,
                                to: 1
                            }
                        });
                    }
                });
            } else {
                if (this.privateState.chart) {
                    this.privateState.chart.detach();
                    this.privateState.chart = null;
                }
            }
        },
        updateData: function updateData() {
            var _this = this;
            var minYear = this.sharedState.metric.years[0];
            var maxYear = this.sharedState.metric.years[this.sharedState.metric.years.length - 1];
            var yearsLength = parseInt(maxYear) - parseInt(minYear) + 1;
            var filledYears = Array.apply(0, Array(yearsLength)).map(function (_, b) {
                return b + parseInt(minYear);
            });
            var chartData = {
                labels: filledYears,
                series: []
            };

            var keys = Object.keys(this.sharedState.metric.data.map);
            var areaArray = [];
            var metric = this.sharedState.metric;

            for (var i = 0; i < chartData.labels.length; i++) {
                var areaValue = null;
                if (this.sharedState.metric.years.indexOf(chartData.labels[i].toString()) !== -1) {
                    if (metric.config.world_val && metric.config.world_val['y_' + chartData.labels[i]]) {
                        areaValue = metric.config.world_val['y_' + chartData.labels[i]];
                    } else {
                        areaValue = (0, _metric_calculations.calcValue)(this.sharedState.metric.data, this.sharedState.metric.config.type, chartData.labels[i], keys);
                    }
                }
                areaArray.push({
                    meta: 'County',
                    value: areaValue
                });
            }
            chartData.series.push(areaArray);

            if (this.sharedState.selected.length > 0) {
                var selectedArray = [];
                for (var _i = 0; _i < chartData.labels.length; _i++) {
                    if (this.sharedState.metric.years.indexOf(chartData.labels[_i].toString()) !== -1) {
                        var selectedValue = (0, _metric_calculations.calcValue)(this.sharedState.metric.data, this.sharedState.metric.config.type, chartData.labels[_i], this.sharedState.selected);
                        selectedArray.push({
                            meta: 'Selected',
                            value: selectedValue
                        });
                    } else {
                        selectedArray.push(null);
                    }
                }
                chartData.series.push(selectedArray);
            }
            return chartData;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sharedState.metric.years.length > 1),expression:"sharedState.metric.years.length > 1"}],staticClass:"qol-chart mdl-color--white mdl-shadow--2dp mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop mdl-typography--text-center"},[_c('div',{staticClass:"trendchart"},[(_vm.sharedState.metric.config)?_c('h1',[_vm._v(_vm._s(_vm.sharedState.metric.config.title))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"legend"},[_c('svg',{staticClass:"icon legend-county"},[_c('use',{attrs:{"xlink:href":"#icon-trending_up"}})]),_vm._v(" Metro Area")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.sharedState.selected.length > 0),expression:"sharedState.selected.length > 0"}],staticClass:"legend"},[_c('svg',{staticClass:"icon legend-selected"},[_c('use',{attrs:{"xlink:href":"#icon-trending_up"}})]),_vm._v(" Selected")]),_vm._v(" "),_c('div',{staticClass:"ct-trendchart"})])])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-4dd67e70"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  module.hot.dispose(__vueify_style_dispose__)
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4dd67e70", __vue__options__)
  } else {
    hotAPI.reload("data-v-4dd67e70", __vue__options__)
  }
})()}